.castingVoteModalContainer{
    .title{
        font-family: 'Recoleta Bold';
        font-size: 2.375rem;
        color: #262626;
        padding: 0;
        margin: 0;
    }

    .voteButtonSection{
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;

        .left{
            .vote-button {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 15px;
                gap: 10px;

                width: 132px;
                height: 102.59px;

                border-radius: 10px;

                img {
                    width: 30.17px;
                    height: 36.59px;
                    // margin-bottom: 10px;
                }
            
                span {
                    font-family: 'Recoleta Bold';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1.125rem;
                    color: #FFFFFF;
                }
            }

            .forBoy{
                background: #5263FF;
            }

            .forGirl{
                background: #CC8DFF
            }
        }

        .right{
            .heading{
                font-family: 'Poppins Medium';
                font-size: 1.375rem;
                color: #9E9E9E;
                padding: 0;
                margin: 0;
                margin-bottom: 5px;
            }
            .subheading{
                font-family: 'Recoleta Bold';
                font-size: 1.625rem;
                color: #262626;
                padding: 0;
                margin: 0;
            }
        }
    }

    .anonymousVote{
        font-family: 'Poppins Medium';
        font-size: 1.375rem;
        color: var(--text-color-1);
    }

    .subtitle{
        font-family: 'Recoleta Bold';
        font-size: 1.625rem;
        color: var(--text-color-1);
    }

    .buttonSection{
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        .leftBtn{
            width: 100%;
            height: 65px;
            font-family: 'Recoleta Bold';
            font-size: 1.375rem;
            color: #FFFFFF;
            border-radius: 10px;
        }

        .leftBtnForBoy{
            background: #5263FF;
        }

        .leftBtnForGirl{
            background: #CC8DFF
        }

        .rightBtn{
            width: 48%;
            height: 120px;
            background: #FFFFFF;
            border-radius: 10px;
            font-family: 'Recoleta Bold';
            font-size: 1.375rem;
        }

        .rightBtnForBoy{
            color: #5263FF;
            border: 2.5px solid rgba(82, 99, 255, 0.15);
        }
        .rightBtnForGirl{
            color: #CC8DFF;
            border: 1px solid rgba(204, 141, 255, 0.25);
        }
    }

    .backButton{
        font-size: 14px;
        font-weight: 500px;
        font-family: "Poppins Medium";
        cursor: pointer;
    }

    .donationTitle{
        font-family: 'Poppins Medium';
        font-size: 1.375rem;
        color: #9E9E9E;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
    }

    .adContainer{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .adScreen{
            height: 250px;
            width: 100%;
            margin-bottom: 10px;
            overflow: hidden;
        }
        .progressWrapper {
            width: 100%;
            height: 10px;
            background: #e5e7eb;
            border-radius: 5px;
            margin-top: 10px;
            overflow: hidden;
          }
          
          .progressBar {
            height: 100%;
            border-radius: 5px 0 0 5px;
            animation: fillProgress 10s linear forwards;
            // transition: width 1s linear;
          }

          @keyframes fillProgress {
            from {
              width: 0%;
            }
            to {
              width: 100%;
            }
          }
          
          .countdownText {
            margin-top: 8px;
            font-size: 14px;
            color: #6b7280;
          }
          
          .btn{
            width: 18%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 55px;
            color: #FFFFFF;
            border-radius: 10px;
            font-family: 'Recoleta Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 1.375rem;
            cursor: pointer;
            margin-top: 25px;
        }

        .btnForBoy{
            background-color: #5263FF;
            border: 2.5px solid rgba(82, 99, 255, 0.15);
        }

        .btnForGirl{
            background-color: #CC8DFF;
            border: 2.5px solid rgba(204, 141, 255, 0.25);
        }

        @media (max-width: 450px){
            padding-top: 20px;
            .btn{
                width: 100%;
                height: 53px;
                margin-top: 15px;
                font-size: 1rem;
            }
        }
    }

    .ant-form-item-explain-error{
        margin-bottom: 10px;
    }
    .donationAmountBtnSection{
        margin-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .btn{
            width: 18%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 55px;
            background: #FFFFFF;
            border-radius: 10px;
            font-family: 'Recoleta Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 1.375rem;
            cursor: pointer;
        }

        .btnForBoy{
            color: #5263FF;
            border: 2.5px solid rgba(82, 99, 255, 0.15);
        }

        .btnSelectedForBoy{
            background: #5263FF;
            color: #FFFFFF
        }

        .btnForGirl{
            color: #CC8DFF;
            border: 2.5px solid rgba(204, 141, 255, 0.25);
        }

        .btnSelectedForGirl{
            background: #CC8DFF;
            color: #FFFFFF
        }
    }

    .donationMethodBtnSection{
        .cardBtn{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 55px;
            background: #FFFFFF;
            border-radius: 5px;
            font-family: 'Recoleta Bold';
            font-size: 1.375rem;
            width: 100%;
            cursor: pointer;
        }

        .cardBtnForBoy{
            color: #5263FF;
            border: 2.5px solid rgba(82, 99, 255, 0.15);
        }

        .btnSelectedForBoy{
            background: #5263FF;
            color: #FFFFFF
        }

        .cardBtnForGirl{
            color: #CC8DFF;
            border: 2.5px solid rgba(204, 141, 255, 0.25);
        }

        .btnSelectedForGirl{
            background: #CC8DFF;
            color: #FFFFFF
        }
    }

    .castingVoteModalInput{
        margin-bottom: 20px;
        padding: 15px;
        width: 100%;
        height: 54px;
        border-radius: 5px;
        font-family: 'Poppins Regular';
        font-size: 1.125rem;
        color: #262626;

        &::placeholder {
			font-family: 'Poppins Regular' !important;;
            font-weight: 400;
            font-size: 1.125rem !important;
            color: #9E9E9E;
		}

		.ant-input {
			&::placeholder {
				font-family: 'Poppins Regular' !important;;
                font-weight: 400;
                font-size: 1.125rem !important;;
                color: #9E9E9E;
			}
		}
    }

    .castingVoteModalInputForBoy{
        border: 2.5px solid rgba(82, 99, 255, 0.15) !important;
        &:focus {
            box-shadow: rgba(82, 99, 255, 0.15) !important;
            border-color: rgba(82, 99, 255, 0.15) !important;
            outline-color: rgba(82, 99, 255, 0.15) !important;
            border: 1px solid rgba(82, 99, 255, 0.15) !important;
        }
        &:hover {
            box-shadow: rgba(82, 99, 255, 0.15) !important;
            border-color: rgba(82, 99, 255, 0.15) !important;
            outline-color: rgba(82, 99, 255, 0.15) !important;
            border: 1px solid rgba(82, 99, 255, 0.15) !important;
        }
        &:active {
            box-shadow: rgba(82, 99, 255, 0.15) !important;
            border-color: rgba(82, 99, 255, 0.15) !important;
            outline-color: rgba(82, 99, 255, 0.15) !important;
            border: 1px solid rgba(82, 99, 255, 0.15) !important;

        }
    }

    .castingVoteModalInputForGirl{
        border: 2.5px solid rgba(204, 141, 255, 0.15) !important;
        &:focus {
            box-shadow: rgba(204, 141, 255, 0.15) !important;
            border-color: rgba(204, 141, 255, 0.15) !important;
            outline-color: rgba(204, 141, 255, 0.15) !important;
            border: 1px solid rgba(204, 141, 255, 0.15) !important;
        }
        &:hover {
            box-shadow: rgba(204, 141, 255, 0.15) !important;
            border-color: rgba(204, 141, 255, 0.15) !important;
            outline-color: rgba(204, 141, 255, 0.15) !important;
            border: 1px solid rgba(204, 141, 255, 0.15) !important;
        }
        &:active {
            box-shadow: rgba(204, 141, 255, 0.15) !important;
            border-color: rgba(204, 141, 255, 0.15) !important;
            outline-color: rgba(204, 141, 255, 0.15) !important;
            border: 1px solid rgba(204, 141, 255, 0.15) !important;
        }
    }

    .errorMessage{
        margin-top: -10px;
        color: var(--error);
        font-size: 1rem;
        font-family: "Poppins Regular";
    }

    .cardDetailsSection{
        margin-top: 30px;

        .castingVoteModalInput{
            margin-bottom: 0px;
        }
        .customLabel {
            font-family: 'Poppins Regular';
            font-size: 1.125rem;
            color: #9E9E9E;
    
            // letter-spacing: -0.7px;
            display: block;
            margin-bottom: 10px;
            margin-top: 25px;
    
            .error{
                padding-top: 30px !important;
                color: var(--error);
                font-size: 0.8125rem;
                font-family: "Poppins Regular";
            }
        }

        .paymentCardSection{
            margin-bottom: 10px;
        }
    }
    
    .donationAmountTitle{
        font-family: 'Poppins Medium';
        font-size: 1.375rem;
        color: #9E9E9E;
        padding: 0;
        margin: 0;
        margin-top: 25px;
        margin-bottom: 10px;
    }

    .donationAmount{
        font-family: 'Recoleta Bold';
        font-weight: 700;
        font-size: 2.375rem;
        color: #262626;
        padding: 0;
        margin: 0;

        .small{
            font-family: 'Poppins Regular';
            font-size: 1.125rem;
            color: #9E9E9E;
        }
    }
    
    .impMessage{
        font-family: 'Poppins Regular';
        margin-top: -2%;
        color: #ff3333,
    }

    .submitVoteBtnSection{
        display: flex;
        flex-direction: column;
        margin-top: 40px;

        .btn{
            padding: 15px;
            width: 100%;
            height: 65px;
            border-radius: 10px;
            font-family: 'Recoleta Bold';
            font-size: 1.5rem;
            text-transform: capitalize;
            color: #FFFFFF;
            margin-bottom: 20px;
            
        }

        .btnForBoy{
            background: #5263FF;
            border: 1px solid rgba(82, 99, 255, 0.15);
            &:hover{
                border: 1px solid rgba(82, 99, 255, 0.15);
                outline: none;
            }
        }

        .btnForGirl{
            background: #CC8DFF;
            border: 1px solid rgba(204, 141, 255, 0.25);

            &:hover{
                border: 1px solid rgba(204, 141, 255, 0.25);
                outline: none;
            }
        }

        .terms{
            font-family: 'Poppins Regular';
            font-size: 0.5rem;
            color: var(--text-color-1);
            margin: 0;
            padding: 0;
        }
    }

    .voteSubmittedSection {
        width: 100%;
        min-height: 400px;
        display: flex;
        flex-direction: column; /* Align items in a column */
        justify-content: center;
        align-items: center;
    
        // .title {
        //     margin-bottom: 1rem;
        //     font-size: 24px; /* Adjust as needed */
        //     text-align: center;
        // }

        .subtitle {
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-size: 18px; /* Adjust as needed */
            text-align: center;
            color:#262626;
            font-family: 'Poppins Medium';
        }

        .registry{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1.5rem;
            padding: 0.9375rem;
            border-radius: 0.625rem;
            background: #FFF;
            border: 1px solid var(--black);
            box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
            margin-bottom: 1rem;
            cursor: pointer;
            .name{
                font-family: 'Poppins Bold';
                font-size: 16px;
                line-height: 130%;
                color: #262626;
            }
        }
    }
    

    @media (max-width: 450px) {
        .title{
            font-size: 1.5rem;
        }

        .voteButtonSection{
            margin-top: 20px;

            .left{
                .vote-button {
                    span {
                        font-size: .9rem;
                    }
                }
            }

            .right{
                .heading{
                    font-size: 1rem;
                }
                .subheading{
                    font-size: 1.125rem;
                }
            }
        }

        .anonymousVote{
            font-size: 0.8125rem;
        }

        .subtitle{
            font-size: 1.125rem;
        }

        .buttonSection{
            margin-top: 30px;
            .leftBtn{
                height: 53px;
                font-size: 1rem;
            }
            .rightBtn{
                height: 65px;
                font-size: 1rem;
            }
        }

        .donationTitle{
            font-size: 0.8125rem;
        }

        .donationAmountBtnSection{
            .btn{
                width: 18%;
                height: 50px;
                font-size: 1rem;
            }
        }

        .donationMethodBtnSection{
            .cardBtn{
                width: 140px;
                height: 45px;
                font-size: 1.125rem;
            }
        }

        .castingVoteModalInput{
            padding: 10px;
            height: 44px;
            font-size: 0.875rem;
            
            &::placeholder {
                font-size: 0.875rem !important;;
            }
    
            .ant-input {
                &::placeholder {
                    font-size: 0.875rem !important;;
                }
            }
        }

        .errorMessage{
            font-size: 0.8125rem;
        }

        .cardDetailsSection{
            .customLabel {
                font-size: 0.875rem;
            }

        }

        .donationAmountTitle{
            font-size: 0.8125rem;
        }

        .donationAmount{
            font-size: 1.5rem;
    
            .small{
                font-size: 0.875rem;
            }
        }

        .impMessage{
            font-family: 'Poppins Regular';
            margin-top: -4%;
            color: #ff3333;
            font-size: .8rem;
        }

        .submitVoteBtnSection{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .btn{
                width: 100%;
                height: 53px;
                font-size: 1rem;
                margin-bottom: 20px;
            }
    
            .terms{
                text-align: center;
                font-size: 0.6875rem;
            }
        }

        .voteSubmittedSection{
        }
    }
}
