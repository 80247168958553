.addNotesModalContainer{
    .ant-form-item-explain-error{
        margin-bottom: 10px;
    }

    .showMessageToMomBox{
        display: flex;
        justify-content: center;

        .internalBox{
            width: 362px;

            .subheadline{
                font-family: 'Poppins SemiBold';
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.03em;
                color: #463649;
                margin-bottom: 28px;
        
                @media (max-width: 450px){
                    text-align: justify;
                    font-size: 18px;
                }
            }
        }
    }
    

    .blog-link{
        color: var(--text-grey);
        font-family: "Poppins Regular" ;
        font-size: 0.875rem;
        line-height: 1.3125rem;
        margin-bottom: 20px !important;
        margin-top: -10px !important;
        text-decoration: underline;
        display: block;

        @media (max-width: 450px){
            text-align: justify;
        }
    }
}