.followOurVillieModalContainer{
    display: flex;
    justify-content: center;
            
    .leftSide{
        position: relative;

        .headline{
            font-family: 'Poppins Bold';
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.03em;
            color: #463649;
            margin-bottom: 16px;
        }
    
        .parentInfoBox {
            position: absolute;
            z-index: 100;
            width: 86%;
            height: 68px;
            left: 7%;
            bottom: 5%;
            background-color: #FFFFFF;
            border-radius: 10px;
        }
    
        .image{
            width: 24.21875rem;
            height: 12.625rem;
            // width: 387.5px;
            // height: auto;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    .rightSide{
        width: 362px;
        .headline{
            font-family: 'Poppins Bold';
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.03em;
            color: #463649;
            margin-bottom: 16px;
        }

        .subheadline{
            font-family: 'Poppins SemiBold';
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.03em;
            color: #463649;
            margin-bottom: 28px;
        }
    }
    .submitBtn{
        width: 351px;
    }
    
    @media (max-width: 450px) {
        .leftSide{
            .image{
                width: 24.21875rem;
                height: 12.625rem;
            }
        }

        .rightSide{
            width: 100%;
            margin: 24px 18.5px 0;

            .subheadline{
                font-size: 18px;
            }
        }
    }
}