.upgradeTierModalContainer{
    background: linear-gradient(48deg, rgba(251, 245, 255, 0.55) 16.93%, rgba(251, 244, 245, 0.70) 46.87%);
    border-radius: 60px;
    padding: 3% 0;
    .title{
        color: #463649;
        text-align: center;
        font-family: "Poppins Bold";
        font-size: 4.0625rem;
        line-height: 4.21894rem;
        letter-spacing: -0.12188rem;
    }
    
    .subheadline{
        font-family: 'Poppins Regular';
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: -0.03em;
        color: #463649;
        margin-bottom: 28px;
        padding: 0 50px;
        text-align: justify;

    }

    .plansBox{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 30px;
        .tier{
            width: 30%;
            height: max-content;
            border-radius: 0.625rem;
            background: #FFF;
            box-shadow: 0px 0px 25px 0px rgba(217, 171, 254, 0.15);
            padding: 3.5%;


            .tierBadge{
                width: max-content;
                height: 15px;
                padding: 0rem 2.0625rem;
                border-radius: 1rem;
                background: #FCE4F9;

                color: #463649;
                text-align: center;
                font-family: "Poppins Bold";
                font-size: 0.625rem;
                line-height: 0.9375rem;
                letter-spacing: 0.04063rem;
                text-transform: uppercase;
                margin-bottom: .5rem;
            }

            .tierPrice{

                color: #463649;
                font-family: "Poppins Bold";
                font-size: 2.8125rem;
                font-style: normal;
                line-height: 3.09375rem;
                letter-spacing: -0.08438rem;
                margin-bottom: .5rem;
            }

            .tierCopy{
                color: #6B7280;
                font-family: "Poppins Regular";
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }

            .tierDetails{
                display: flex;
                align-items: center;
                margin-bottom: .5rem;
                gap: .5rem;
                .icon{
                    width: 0.875rem;
                    height: 0.75rem;
                }
                .copy{
                    color: #463649;
                    font-family: "Poppins Regular";
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.6875rem;
                }
            }
        }
    }

    @media(max-width: 450px){
        .title{
            font-size: 2rem;
            line-height: 2rem; 
            letter-spacing: -0.06rem;
        }
        .subheadline{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
            padding: 0 14px;
        }
        .plansBox{
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
            .tier{
                width: 94%;
            }
        }
    }
    
}

.upgradeTierModal{
    
    @media (max-width: 450px){
        top: 0%;
    }
}